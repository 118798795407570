<template>
  <div class="splash-page" data-testid="element_000310">
    <div class="custom-wrapper" data-testid="element_000311">
      <div class="notice" data-testid="element_000312">
        <svg class="icon" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <use href="/svg/18.svg#18" />
        </svg>
        <div class="title stl-text-22wb600v2 stl-color-white" data-testid="element_000313">Warning: This Website is for Adults Only!</div>
        <div class="text stl-text-14wb400v2" data-testid="element_000314">
          This Website is for use solely by individuals at least 18-years old (or the age of consent in the
          jurisdiction from which you are accessing the Website). The materials that are available on this Website
          include graphic visual depictions and descriptions of nudity and sexual activity and must not be accessed by
          anyone who is under 18-years old and the age of consent. Visiting this Website if you are under 18-years old
          and the age of consent might be prohibited by the law of your jurisdiction.
          <br />
          <br />
          By clicking “Agree” below, you state that the following statements are accurate:
          <br />I am an adult, at least 18-years old, and the age of consent in my jurisdiction, and I have the right
          to access and possess adult material in my community.
          <br />I will not allow any person under 18-years old to have access to any of the materials contained within
          this Website.
          <br />I am voluntarily choosing to access this Website because I want to view, read, or hear the various
          available materials.
          <br />I do not find images of nude adults, adults engaged in sexual acts, or other sexual material to be
          offensive or objectionable.
          <br />I will leave this Website promptly if I am in any way offended by the sexual nature of any material.
          <br />I understand and will abide by the standards and laws of my community.
          <br />
          By logging on and viewing any part of the Website, I will not hold the Website’s owners or its employees
          responsible for any materials located on the Website.
          <br />I acknowledge that the Website’s Terms-of-Service Agreement governs my use of the Website, and I have
          reviewed and agreed to be bound by those terms.
          <br />
          If you do not agree, click on the “I Disagree” button below and exit the Website.
          <br />
          <br />
          Date: December 23, 2022
        </div>
        <div class="actions" data-testid="element_000315">
          <button-component @click="setAgeTruth" class="button button_primary button_txt-trm-none">
            Agree
          </button-component>
          <button-component class="button button_second button_txt-trm-none">
            <a :href="'https://www.playavr.com/'">
              Disagree
            </a>
          </button-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ButtonComponent from '@/components/ui/button/button.vue'

interface Props {
  age: boolean
}

const { height } = useSSRWindowSize()
const props = defineProps<Props>()
const { age } = toRefs(props)

watch(age, (isAge) => {
  if (isAge) {
    setAgeTruth()
  }
})

const emit = defineEmits<{
  change: [boolean]
  overflow: ['block' | 'none']
}>()

if (!age.value) {
  emit('overflow', 'none')
}

const setAgeTruth = () => {
  emit('overflow', 'block')
  emit('change', true)
}
</script>

<style lang="scss">
.splash-page {
  width: 100%;
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  height: 100%;
  background: #121212 !important;

  .custom-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 48rem;
    min-height: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    width: 100vw;
    margin-top: 0;
    background: url(/img/plugs/background.png) no-repeat bottom right;
    background-size: 1264rem 1080rem;
    transform: scaleX(1);

    @include bp-tablet() {
      background: url(/img/plugs/background-tablet.png) no-repeat bottom right;
      background-size: 768rem 954rem;
      padding: 48rem 24rem;
    }

    @include bp-mobile() {
      background: url(/img/plugs/background-mini.png) no-repeat bottom right;
      padding: 24rem 12rem;
    }
  }

  .notice {
    background: map-get($tonePalette, tone_1);
    align-items: center;
    display: flex;
    width: 1240rem;
    flex-direction: column;
    gap: 24rem;
    padding: 48rem;
    position: relative;
    border-radius: 30rem;

    @include bp-mobile() {
      padding: 24rem 12rem;
    }

    .title {
      @include bp-mobile() {
        text-align: center;
      }
    }

    .text {
      letter-spacing: 0.28px;

      @include bp-tablet() {
        max-height: calc(100vh - 300rem);
        overflow-y: auto;
      }

      @include bp-mobile() {
        height: 310rem;
        overflow-y: scroll;
        margin-right: -5rem;
        padding-right: 5rem;

        &::-webkit-scrollbar {
          width: 2px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5rem;
          background: map_get($grayPalette, "color_9b");
        }

        &::-webkit-scrollbar-track {
          border-radius: 5rem;
          background: map_get($grayPalette, "color_36");
        }
      }
    }
    .button {
      width: 120rem;

      @include bp-mobile() {
        width: 100%;
      }

      a {
        font-weight: 600;

        &:hover {
          color: #fff;
        }
      }
    }

    .button_second {
      height: 40rem;
    }
  }

  .icon {
    max-width: 72rem;

    @include bp-mobile() {
      max-width: 64rem;
    }
  }

  .actions {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 14rem;
    justify-content: center;
    position: relative;
    width: 100%;
  }
}
</style>
