import { ref, provide } from 'vue'
import { useConditionStore } from "~/stores/condition"

export default function useLayoutDefault () {
  const conditionStore = useConditionStore()
  const { width } = useSSRWindowSize()

  const visibleMobileSearch = useState<boolean>('visibleMobileSearch', () => false)
  const visibleLeftMenu = useState<boolean>('visibleLeftMenu', () => false)
  const visibleUserPopup = useState<boolean>('visibleUserPopup', () => false)

  function closeAllModal () {
    visibleMobileSearch.value = false
    visibleUserPopup.value = false
    visibleLeftMenu.value = false
  }

  function changeVisibleModal (value: boolean, action: Function) {
    closeAllModal()
    action()
  }

  function changeVisibleMobileSearch (value: boolean) {
    changeVisibleModal(value, () => {
      visibleMobileSearch.value = value
    })
  }

  function changeVisibleLeftMenu (value: boolean) {
    conditionStore.activeBody(value)
    changeVisibleModal(value, () => {
      visibleLeftMenu.value = value
    })
  }

  function changeVisibleUserPopup (value: boolean) {
    changeVisibleModal(value, () => {
      visibleUserPopup.value = value
    })
  }

  const leftBlockClasses = computed(() => {
    return [
      'modal-left-menu',
      visibleLeftMenu.value ? 'modal-left-menu_show' : 'modal-left-menu_hide',
    ]
  })

  watch(width, () => {
    if (visibleMobileSearch.value && width.value > 1199) {
      visibleMobileSearch.value = false
    }
  })
  watch(visibleMobileSearch, () => {
    conditionStore.activeBody(visibleMobileSearch.value)
  })

  provide('visibleMobileSearch', visibleMobileSearch)
  provide('changeVisibleMobileSearch', changeVisibleMobileSearch)

  provide('visibleLeftMenu', visibleLeftMenu)
  provide('changeVisibleLeftMenu', changeVisibleLeftMenu)

  provide('visibleUserPopup', visibleUserPopup)
  provide('changeVisibleUserPopup', changeVisibleUserPopup)

  return {
    visibleMobileSearch,
    visibleLeftMenu,
    visibleUserPopup,
    changeVisibleMobileSearch,
    changeVisibleLeftMenu,
    changeVisibleUserPopup,
    leftBlockClasses,
  }
}
