<template>
  <div
    v-if="visibleUserPopup"
    class="user-popup-drawer-wrapper"
    data-testid="elem_vrp_000793"
    @click.prevent="clickOutsideVisibleUserPopup"
  >
    <div class="user-popup-drawer" data-testid="elem_vrp_000794" @click.prevent>
      <div class="user-popup-drawer__mobile-line-wrapper" data-testid="elem_vrp_000795">
        <div class="user-popup-drawer__mobile-line" data-testid="elem_vrp_000796"></div>
      </div>
      <NuxtLink :to="{ name: 'account-setting' }" class="user-popup-drawer__icon-btn" data-testid="elem_vrp_000797">
        <svg
          viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/settings.svg#settings" />
        </svg>
        <span data-testid="elem_vrp_000798">ACCOUNT SETTINGS</span>
      </NuxtLink>
      <NuxtLink v-if="isMobile" :to="{ name: 'account-notification' }" class="user-popup-drawer__icon-btn">
        <div class="user-popup-drawer__notification-icon">
          <svg
            viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <use href="/svg/bell.svg#bell" />
          </svg>
          <div
            v-if="isUnreadBell"
            class="user-popup-drawer__notification-icon-sphere-dot"
          />
        </div>
        <span data-testid="elem_vrp_000799">NOTIFICATIONS</span>
      </NuxtLink>
      <NuxtLink v-if="isMobile" :to="{ name: 'account-library' }" class="user-popup-drawer__icon-btn" data-testid="elem_vrp_000800">
        <svg
          viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/heart.svg#heart" />
        </svg>
        <span data-testid="elem_vrp_000801">FAVORITES</span>
      </NuxtLink>
      <NuxtLink v-if="visibleCreatorsPortal" :to="{ name: 'account-earnings' }" class="user-popup-drawer__icon-btn" data-testid="elem_vrp_000802">
        <svg
          viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/money-bag.svg#money-bag" />
        </svg>
        <span data-testid="elem_vrp_000803">CREATOR'S PORTAL</span>
      </NuxtLink>
      <div class="user-popup-drawer__line" data-testid="elem_vrp_000804" />
      <div class="user-popup-drawer__icon-btn user-popup-drawer__icon-btn--logout" data-testid="elem_vrp_000805" @click="userStore.logout">
        <svg
          viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use href="/svg/logout.svg#logout" />
        </svg>
        <span data-testid="elem_vrp_000806">LOG OUT</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from "~/stores/user"
import { useNotificationsStore } from "~/stores/notifications"
import { computed } from "vue"

const userStore = useUserStore()
const { visibleUserPopup, changeVisibleUserPopup } = useLayoutDefault()

function clickOutsideVisibleUserPopup() {
  if (visibleUserPopup.value) {
    changeVisibleUserPopup(false)
  }
}

const { width } = useSSRWindowSize()

const isMobile = computed(() => {
  if (import.meta.server) {
    return false
  }
  return width.value < 600
})

const notificationsStore = useNotificationsStore()
const isUnreadBell = computed(() => notificationsStore.isUnreadBell)

const visibleCreatorsPortal = computed(() => {
  return userStore.isRole.superAdmin || userStore.isRole.studioOwner
})
</script>

<style lang="scss">
.user-popup-drawer-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 10;
  cursor: pointer;
}
.user-popup-drawer {
  margin-top: 6rem;
  position: absolute;
  width: 288rem;
  top: 66rem;
  right: 24rem;
  background: map_get($grayPalette, "color_17");
  border-radius: 10rem;
  padding: 12rem;
  z-index: 20;

  @include bp-mobile('max') {
    margin-top: 0;
    margin-bottom: 6rem;
    top: unset;
    left: calc((100% - 288rem) / 2);
    right: unset;
    bottom: 56rem;
  }

  &__mobile-line-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include bp-mobile('max') {
    &__mobile-line {
      width: 40rem;
      height: 3rem;
      background: map-get($grayPalette, 'color_28');
      border-radius: 3rem;
      text-align: center;
    }
  }

  & &__line {
    display: block;
    background: map_get($grayPalette, "color_28");
    width: 100%;
    height: 1rem;
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  &__icon-btn {
    margin-top: 4rem;
    margin-left: 0;
    padding: 12rem;
    width: 100%;
    height: 45rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: map_get($grayPalette, "color_ff");
    line-height: normal;

    @include bp-mobile('max') {
      font-size: 14rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;

      &:first-of-type {
        margin-top: 16rem !important;
      }
    }

    svg {
      margin-right: 8rem;
      padding: 0;
      width: 20rem;
      max-width: 20rem;
      height: 20rem;
    }

    &--logout, &:first-of-type {
      margin-top: 0;
      span {
        display: inline-block;
        line-height: 21rem;
      }
    }
  }

  &__notification-icon {
    position: relative;

    &-sphere-dot {
      position: absolute;
      top: 0;
      right: 10rem;
      min-width: 5rem;
      min-height: 5rem;
      background: map-get($colorPalette, 'color_81c');
      border: 2rem solid map-get($grayPalette, 'color_12');
      border-radius: 100%;
      box-sizing: content-box;
    }
  }
}
</style>
