<template>
  <modal-container :value="conditionStore.modal.setNewPassword" title="Set New Password" @close="close">
    <p class="ui-account-modal-container__paragraph" data-testid="elem_vrp_000758">Please, enter your new password.</p>

    <div v-if="!isReset" class="mt2-6" data-testid="elem_vrp_001079">
      <ui-input
        type="password"
        path="currentPassword"
        label="Password"
        required
        autocomplete="current-password"
        tabindex="11"
      />
    </div>

    <div class="mt2-6" data-testid="elem_vrp_001080">
      <ui-input
        type="password"
        path="newPassword"
        label="New Password"
        required
        autocomplete="new-password"
        tabindex="12"
      />
    </div>

    <div class="mt2-6" data-testid="elem_vrp_001081">
      <ui-input
        type="password"
        path="confirmPassword"
        label="Confirm New Password"
        required
        autocomplete="new-password"
        tabindex="13"
      />
    </div>

    <div class="ui-account-modal-container__actions" data-testid="elem_vrp_000759">
      <component-button class="button button_primary button_full-width" @click="apply">
        Apply
      </component-button>
    </div>
  </modal-container>
</template>

<script setup lang="ts">
import ModalContainer from "~/components/ui/account/modal/modal-container.vue";
import { useConditionStore } from "~/stores/condition";
import ComponentButton from "~/components/ui/button/button.vue";
import * as yup from "yup";
import {
  REGEXP_EMAIL_PATTERN,
  REGEXP_NEW_PASSWORD_YUP_OBJECT,
  REGEXP_PASSWORD_PATTERN,
} from "~/shared/constants/regexp";
import { useForm } from "vee-validate";
import { useUserStore } from "~/stores/user";
import { USER_ERRORS } from "~/shared/constants/user-errors";

const nuxtApp = useNuxtApp();
const route = useRoute();
const conditionStore = useConditionStore();
const userStore = useUserStore();

const isResetField = ref(route.query?.type === "reset" && route.query?.token);
const isUnblockField = ref(route.query?.type === "unblock" && route.query?.token);
const isReset = ref(!!(isResetField.value || isUnblockField.value))
if (isResetField.value || isUnblockField.value) {
  conditionStore.modal.setNewPassword = true;
}

const validationSchema = yup.object({
  ...(isReset.value ? {} : {
    currentPassword: yup.string()
      .min(4, USER_ERRORS.currentPassword.invalid)
      .required(USER_ERRORS.currentPassword.required),
  }),
  newPassword: REGEXP_NEW_PASSWORD_YUP_OBJECT,
  confirmPassword: yup.string()
    .oneOf([yup.ref("newPassword")], USER_ERRORS.confirmPassword.notMatch)
    .required(USER_ERRORS.confirmPassword.required),
});

type Form = {
  currentPassword?: string
  newPassword: string
  confirmPassword: string
}

const form = useForm<Form>({
  validationSchema,
  initialValues: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  keepValuesOnUnmount: false,
});
const { $toast } = useNuxtApp();

async function apply() {
  await form.validate();
  const errors = Object.values(form.errors.value)
  if (errors.length) {
    errors.forEach(error => $toast.info(error))
    return;
  }

  const values = form.values;
  if (isReset.value) {
    const response = await userStore.resetPassword({
      token: route.query.token as string,
      type: isResetField.value ? "reset" : 'unblock',
      password: values.newPassword,
    });

    if (response.status.code === 220) {
      nuxtApp.$toast.info(response.status.message);
      return
    }
    if (response.$http.status === 200) {
      conditionStore.modal.forgotPasswordSuccess = true;
      nuxtApp.$toast.info("Password success rested");
    }

    conditionStore.modal.setNewPassword = false;
  } else {
    const response = await userStore.setNewPassword({
      password: values.currentPassword || "",
      new_password: values.newPassword,
      new_password_confirmation: values.confirmPassword,
    });

    if (response.$http.status === 200 && response?.status?.code !== 401) {
      userStore.setTokens(response.token?.atoken, response.token?.rtoken);
      await userStore.getInfoAboutMe();
      conditionStore.modal.forgotPasswordSuccess = false;
      conditionStore.modal.setNewPassword = false;
      nuxtApp.$toast.info("Password has been successfully changed");
      return;
    }

    if (response?.status?.message === 'Unauthorized.') {
      form.setFieldError("currentPassword", 'Wrong password');
      $toast.info('Wrong password');
    }
    if (response.password) {
      form.setFieldError("currentPassword", response.password);
      $toast.info(response.password);
    }
    if (response.new_password) {
      form.setFieldError("newPassword", response.new_password);
      $toast.info(response.new_password);
    }
    if (response.new_password_confirmation) {
      form.setFieldError("confirmPassword", response.new_password_confirmation);
      $toast.info(response.new_password_confirmation);
    }
  }
}

const close = () => {
  conditionStore.modal.setNewPassword = false;
};
</script>
