<template>
  <client-only>
    <div v-if="visibleMobileSearch" :key="String(visibleMobileSearch)" class="mobile-search-modal" data-testid="element_000197" @click.stop="changeVisibleMobileSearch(false)">
      <search :floatingCatalog="true" class="mobile-search" data-testid="element_000198" @click.stop />
    </div>
  </client-only>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import Search from '~/components/ui/search/search.vue'

const {
  changeVisibleMobileSearch,
} = useLayoutDefault()

const route = useRoute()

const visibleMobileSearch = inject('visibleMobileSearch', false)

watch(() => route.path, () => {
  changeVisibleMobileSearch(false)
})
</script>

<style lang="scss">
.mobile-search-modal {
  width: 100%;
  height: 100%;
  max-height: calc(100% - 56rem - 56rem);
  position: fixed;
  top: 56rem;
  left: 0;
  z-index: 5;
  overflow-y: auto;

  .ui-search {
    width: 100%;
    border-top: 1rem solid map-get($grayPalette, "color_28");

    .ui-search__field-wrapper {
      position: relative;
      padding: 8rem 12rem;
      background: map-get($grayPalette, 'color_12');
      border-radius: 0 0 10rem 10rem;
    }

    .ui-search__field-input-icon_glass {
      display: none;
    }

    .ui-search__field-input-icon_loader,
    .ui-search__field-input-icon_close {
      top: 23rem;
      right: calc(14rem + 12rem);
    }

    input {
      width: 100%;
      padding: 13rem 14rem;
      min-height: 34rem;
    }

    .ui-search__field-input {
      width: 100%;
      display: grid;
      position: relative;
    }

    .ui-search__categories {
      margin-top: 4rem;
      position: relative;
      width: 100% !important;
      height: 100% !important;
      padding: 0;

      @include bp-desktopXS('min') {
        margin-top: 0;
      }
    }

    .ui-search__list {
      padding: 12rem;
    }
  }
}
</style>
