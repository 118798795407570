<template>
  <div v-if="isVisible" class="app-bottom-navigation" data-testid="elem_vrp_000786">
    <button-component
      tag="NuxtLink"
      class="button button_second app-bottom-navigation__button"
      :to="{ name: 'new' }"
      title="New Videos"
      data-testid="elem_vrp_000787"
    >
      <BottomNavigationVideoIcon viewBox="0 0 16 16" />
    </button-component>
    <button-component
      tag="NuxtLink"
      class="button button_second app-bottom-navigation__button"
      :to="{ name: 'camgirls' }"
      title="Live VR Smash"
      data-testid="elem_vrp_000788"
    >
      <BottomNavigationWebcamIcon viewBox="0 0 16 16" />
    </button-component>

    <button-component
      tag="NuxtLink"
      :to="{ name: userStore.isAuthorized ? 'buy-tokens' : 'premiumlp' }"
      :title="userStore.isAuthorized ? 'Buy Tokens' : 'Join Now'"
      class="button button_primary app-bottom-navigation__button-main"
      data-testid="elem_vrp_000789"
    >
      <span class="app-bottom-navigation__button-main-text" data-testid="elem_vrp_000790">
        {{ btnText }}
      </span>
    </button-component>

    <button-component
      tag="NuxtLink"
      class="button button_second app-bottom-navigation__button"
      :to="{ name: 'categories' }"
      title="Categories"
      data-testid="elem_vrp_000791"
    >
      <BottomNavigationSettingIcon viewBox="0 0 16 16" />
    </button-component>
    <button-component
      tag="NuxtLink"
      class="button button_second app-bottom-navigation__button user"
      :to="{ name: 'login' }"
      title="Login"
      data-testid="elem_vrp_000792"
      @click.capture.prevent="userBtnAction"
    >
      <BottomNavigationUserIcon viewBox="0 0 16 16" />
      <div v-if="isUnreadBell" class="app-bottom-navigation__sphere-dot" data-testid="elem_vrp_001090"></div>
    </button-component>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from "~/stores/user"
import ButtonComponent from "~/components/ui/button/button.vue"
import BottomNavigationVideoIcon from '~/public/svg/bottom-navigation/video.svg'
import BottomNavigationWebcamIcon from '~/public/svg/bottom-navigation/webcam.svg'
import BottomNavigationSettingIcon from '~/public/svg/bottom-navigation/setting.svg'
import BottomNavigationUserIcon from '~/public/svg/bottom-navigation/user.svg'
import { useNotificationsStore } from "~/stores/notifications"
import { computed } from "vue"

const { width } = useSSRWindowSize()
const isVisible = computed(() => {
  return width.value < 600
})

const userStore = useUserStore()
const { visibleUserPopup, changeVisibleUserPopup } = useLayoutDefault()
const router = useRouter()

const userBtnAction = () => {
  if (userStore.isAuthorized) {
    return changeVisibleUserPopup(!visibleUserPopup.value)
  }
  return router.push({ name: 'login' })
}

const btnText = computed(() => {
  if (!userStore.isAuthorized) {
    return 'JOIN NOW'
  }
  return 'BUY TOKENS'
  // if premium 'BUY TOKENS'
})

const notificationsStore = useNotificationsStore()
const isUnreadBell = computed(() => notificationsStore.isUnreadBell)
</script>

<style lang="scss">
.app-bottom-navigation {
  padding: 8rem 12rem;
  gap: 0 10rem;
  display: flex;
  align-items: center;
  position: fixed;
  height: 56rem;
  left: 0;
  right: 0;
  bottom: 0;

  border-top: 1px solid map-get($grayPalette, 'color_28');
  background: map-get($grayPalette, 'color_12');

  z-index: 10;

  &__button {
    padding: 12rem !important;
    width: 40rem;
    height: 40rem;

    &.user {
      position: relative;

      .app-bottom-navigation__sphere-dot {
        position: absolute;
        top: 4rem;
        right: 4rem;
        min-width: 5rem;
        min-height: 5rem;
        background: map-get($colorPalette, 'color_81c');
        border: 2rem solid map-get($grayPalette, 'color_12');
        border-radius: 100%;
        box-sizing: content-box;
      }
    }
  }

  &__button-main {
    padding: unset !important;
    width: auto;
    min-width: 136rem;
    height: 40rem;
    background: map-get($colorPalette, 'color_09') !important;

    &-text {
      color: map-get($grayPalette, 'color_ff');
      font-size: 14rem;
      font-style: normal;
      font-weight: 600;
      line-height: 22rem;
    }
  }
}
</style>
