<template>
  <section
      v-if="isRender"
      class="ui-playa-install-modal"
      data-testid="elem_vrp_000087"
  >
    <div class="ui-playa-install-modal__wrapper" data-testid="elem_vrp_000179" @click.stop>
      <div class="ui-playa-install-modal__header" data-testid="elem_vrp_000088">
        <svg v-if="isShowIconBack" class="ui-playa-install-modal__icon-back" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" @click="stepBackward">
          <path d="M20 12H4M4 12L10 6M4 12L10 18" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg class="ui-playa-install-modal__icon-close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" @click="closeDrawer" data-testid="elem_vrp_000089">
          <path d="M18 6.00005L6.00005 18M6 6L12 12L18 18" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="ui-playa-install-modal__container" data-testid="elem_vrp_000090">
        <template v-if="modalStep === 'ask'">
          <h3 class="ui-playa-install-modal__title stl-color-white" data-testid="elem_vrp_000091">
            Do you have the PLAY’A app
            <br>
            installed on your device?
          </h3>
          <img
            src="/img/playa/playa-install-modal.png"
            alt="Playa logo with background"
            class="ui-playa-install-modal__ask-image"
            data-testid="elem_vrp_000597"
          >
          <div class="ui-playa-install-modal__actions">
            <ui-button class="button-ask-primary button button_primary button_full-width" @click="modalStep = 'device'; isHaveApp = false">
              I don’t have the app
            </ui-button>
            <ui-button class="button-ask-secondary button button_full-width" @click="modalStep = 'success'; isHaveApp = true">
              I do have the app
            </ui-button>
          </div>
        </template>
        <template v-if="modalStep === 'device'">
          <h3 class="ui-playa-install-modal__title stl-color-white" data-testid="elem_vrp_000091">
            Let’s start by choosing your
            <br>
            preferred device.
          </h3>
          <p class="ui-playa-install-modal__device-paragraph stl-color-white" data-testid="elem_vrp_000598">Please, select your headset model below.</p>
          <div class="ui-playa-install-modal__device-select">
            <ui-input-select
              path="device"
              path-search="deviceSearch"
              label="Choose Device"
              objected
              :items="deviceChoose"
              item-key="value"
              item-label="label"
              required
            />
          </div>
          <ui-button class="button-ask-primary button button_primary button_full-width" @click="setDownloadStep">
            Continue
          </ui-button>
        </template>
        <template v-if="modalStep === 'download'">
          <h3 class="ui-playa-install-modal__title stl-color-white" data-testid="elem_vrp_000091">
            Now let’s install the PLAY’A app on
            your VR device.
          </h3>
          <img
              src="/img/playa/playa-install-modal.png"
              alt="Playa logo with background"
              class="ui-playa-install-modal__ask-image"
              data-testid="elem_vrp_000599"
          >
          <p class="ui-playa-install-modal__download-paragraph" data-testid="elem_vrp_000600">
            Click on the button below to download the app.
          </p>
          <div class="ui-playa-install-modal__actions" data-testid="elem_vrp_000601">
            <ui-button class="button-ask-primary button button_primary button_full-width">
              Download PLAY’A
            </ui-button>
            <ui-button class="button-ask-secondary button button_full-width" @click="modalStep = 'success'">
              Continue
            </ui-button>
          </div>
        </template>
        <template v-if="modalStep === 'success'">
          <h3 class="ui-playa-install-modal__title stl-color-white" data-testid="elem_vrp_000091">
            Great job!
            <br>
            Follow these steps to watch our
            <br>
            videos on your headset.
          </h3>
          <ol class="ui-playa-install-modal__success-list" data-testid="elem_vrp_000602">
            <li class="ui-playa-install-modal__success-item" data-testid="elem_vrp_000603">Launch the PLAY’A App on your headset.</li>
            <li class="ui-playa-install-modal__success-item" data-testid="elem_vrp_000603">
              Click the button “<a role="button" data-testid="elem_vrp_000605">+ Add Website</a>” and type vrsmash.com in the pop up window, then click “<a role="button" data-testid="elem_vrp_000606">Connect</a>”.
            </li>
            <li class="ui-playa-install-modal__success-item" data-testid="elem_vrp_000603">Login to your VRSmash account.</li>
            <li class="ui-playa-install-modal__success-item" data-testid="elem_vrp_000603">Enjoy!</li>
          </ol>
          <div class="ui-playa-install-modal__actions" data-testid="elem_vrp_000608">
            <ui-button class="button-ask-primary button button_primary button_full-width" @click="closeDrawer">
              OK
            </ui-button>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useConditionStore } from '~/stores/condition'

const conditionStore = useConditionStore()
const isRender = computed(() => conditionStore.modal.playaInstall)
const isHaveApp = ref(false)

const showDrawer = () => {
  modalStep.value = 'ask'
  conditionStore.modal.playaInstall = true
  conditionStore.activeBody(true)
}
const closeDrawer = () => {
  conditionStore.modal.playaInstall = false
  conditionStore.activeBody(false)
}

watch(isRender, (status) => {
  if (status) {
    showDrawer()
  } else {
    closeDrawer()
  }
})

const modalStep = ref<'ask' | 'device' | 'download' | 'success'>('ask')

const isShowIconBack = computed(() => {
  const allowStep = ['download', 'success']
  return allowStep.includes(modalStep.value)
})

const validationSchema = yup.object({
  device: yup.string().required('required field'),
  deviceSearch: yup.string(),
})

type Form = {
  device: string
  deviceSearch: string
}

const form = useForm<Form>({
  validationSchema,
  initialValues: {
    device: '',
    deviceSearch: '',
  },
  keepValuesOnUnmount: false,
})

const setDownloadStep = form.handleSubmit((values) => {
  if (!values.device) {
    return
  }

  modalStep.value = 'download'
})

const deviceChoose = [
  { label: 'Windows', value: 'x1' },
  { label: 'Android', value: 'x2' },
  { label: 'iOS', value: 'x3' },
  { label: 'Linux (Debian)', value: 'x3' },
]

function stepBackward() {
  if (isHaveApp.value) {
    modalStep.value = 'ask'
  }
  const alias: { [key: string]: string } = {
    download: 'device',
    success: 'download',
  }
  modalStep.value = alias[modalStep.value as string] || 'ask'
}
</script>

<style lang="scss">
/* download-modal */
.ui-playa-install-modal {
  cursor: pointer;
  position: fixed;
  inset: 0;
  border-bottom: 0;
  background-color: map-get($tonePalette, 'tone_1');
  z-index: 20;

  &__wrapper {
    cursor: auto;
    max-width: 100%;
    width: 400rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20rem;
    background-color: map-get($grayPalette, 'color_12');

    @include bp-mobile('max') {
      width: 336rem;
    }
  }

  &__header {
    cursor: pointer !important;
    padding: 20rem 32rem 14rem 32rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    @include bp-mobile('max') {
      padding: 20rem 24rem 14rem 24rem;
    }
  }
  &__icon-back {
    margin-right: auto;
    cursor: pointer !important;
    color: map-get($grayPalette, 'color_ff');
    max-width: 24rem;
    width: 24rem;
    height: 24rem;
  }
  &__icon-close {
    cursor: pointer !important;
    max-width: 24rem;
    width: 24rem;
    height: 24rem;
    color: map-get($grayPalette, 'color_ff');
  }
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0 36rem 36rem;

    @include bp-mobile('max') {
      padding: 0 24rem 36rem;
    }
  }
  &__title {
    margin: 0;
    color: map-get($grayPalette, 'color_ff');
    font-size: 18rem;
    font-weight: 600;
    line-height: 27rem;

  }
  .app-page-video-secondary__card {
    margin-top: 24rem;
  }
  &__list {
    margin-top: 24rem;
  }
  &__item {
    padding: 12rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid map-get($grayPalette, 'color_36');
    }
  }
  &__item-name-label {
    position: relative;
    margin-left: 16rem;
    color: map-get($grayPalette, 'color_ff');
    font-size: 16rem;
    font-weight: 600;
    line-height: 24rem;

    &::before {
      content: '';
      position: absolute;
      left: -16rem;
      top: 6rem;
      width: 10rem;
      height: 10rem;
      border-radius: 100%;
      background-color: #DD066D;
    }
  }
  &__item-size {
    display: flex;
    align-items: center;
  }
  &__item-size-label {
    color: map-get($grayPalette, 'color_9b');
    font-size: 16rem;
    font-weight: 400;
    line-height: 24rem;
  }
  &__item-size-icon {
    margin-left: 12rem;
  }

  .button {
    text-transform: unset !important;
  }
  .button-ask-primary {
    margin-top: 24rem;
  }
  .button-ask-secondary {
    margin-top: 12rem;
    background: map-get($grayPalette, 'color_28') !important;
  }

  &__ask-image {
    margin-top: 24rem;
    height: 160rem;
    object-fit: cover;
  }

  &__download-paragraph,
  &__device-paragraph {
    margin: 24rem 0 0;
    color: map-get($grayPalette, 'color_9b');
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;
  }

  &__download-paragraph {
    margin: 12rem 0 0;
    color: map-get($grayPalette, 'color_ff');
  }

  &__device-select {
    margin: 12rem 0 0;
    display: grid;
    height: 56rem !important;
    .field {
      display: grid !important;
    }
  }

  &__success-list {
    margin: 24rem 0 0;
    padding: 0 0 0 16rem;

    @include bp-mobile() {
      padding: 0 0 0 21rem;
    }
  }
  &__success-item {
    list-style-type: decimal;
    color: map-get($grayPalette, 'color_ff');
    font-size: 14rem;
    line-height: 22rem;
    letter-spacing: 0.28rem;
    a {
      color: map-get($colorPalette, 'color_09');
      font-size: 14rem;
      line-height: 22rem;
      font-weight: 400;
      letter-spacing: 0.28rem;
      cursor: text;
    }
  }
}
</style>
