<template>
  <div v-click-outside="clickOutside" class="ui-search" data-testid="element_000199">
    <div :class="inputClasses" class="ui-search__field-wrapper" data-testid="element_000200">
      <form @submit.prevent="onSearch">
        <input
          type="text"
          role="searchbox"
          :value="searchText"
          :placeholder="placeholder"
          data-testid="element_000201"
          @input="onInput"
          @change="onInput"
          @click="inputFocus = true"
        >
      </form>
      <svg
        class="ui-search__field-input-icon ui-search__field-input-icon_glass" width="16" height="16"
        viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use href="/svg/search.svg#search"/>
      </svg>

      <svg
        v-if="loaded" class="ui-search__field-input-icon ui-search__field-input-icon_loader logo-vrp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <path
          fill="currentColor"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
            to="360 50 50" repeatCount="indefinite"
          />
        </path>
      </svg>
      <svg
        v-else-if="searchText" class="ui-search__field-input-icon ui-search__field-input-icon_close" width="20"
        height="20" viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg" @click.stop="close"
      >
        <use href="/svg/close.svg#close"/>
      </svg>
    </div>
    <div
      v-if="categories.length && inputFocus"
      class="ui-search__categories"
      data-testid="element_000202"
    >
      <div class="ui-search__list">
        <div
          v-for="({ key, title, icon, isExpanded, links, styleClass, count }, categoryId) in categories"
          :key="categoryId"
          :class="categoryClasses(styleClass)" data-testid="element_000203"
        >
          <div
            class="ui-search__info" data-testid="element_000204"
            @click="changeCategoryExpanded(categoryId)"
          >
            <div
              role="link"
              class="ui-search__main"
              data-testid="element_000205"
              @click.stop="openSearchPageByKey(key)"
            >
              <svg
                width="16" height="17" viewBox="0 0 16 17" fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <use :href="'/svg/' + icon"/>
              </svg>
              <div class="ui-search__title" data-testid="element_000206">
                {{ title }}
              </div>
              <div class="ui-search__count" data-testid="element_000207">
                ({{ count }})
              </div>
            </div>
            <div class="expanded">
              <ui-icon :name="isExpanded ? 'Linear/Arrows/Alt Arrow Right': 'Linear/Arrows/Alt Arrow Down'" />
            </div>
          </div>
          <collapse :when="isExpanded" class="v-collapse">
            <div class="ui-search__links" data-testid="element_000208">
              <template v-for="link in links" :key="link.id">
                <ui-link v-if="['game', 'video'].includes(key)" :link="{ to: `/video/${link.slug}/` }" :class="`-${key}`" class="ui-search__link" @click="close">
                  <img v-if="link.image?.path" :src="link.image.path" class="ui-search__link-image" data-testid="element_000209">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>

                <ui-link v-if="key === 'camgirls'" :link="{ to: `/camgirls/${link.slug}/` }" :class="`-${key}`" class="ui-search__link" @click="close">
                  <img v-if="link.image?.path" :src="link.image.path" class="ui-search__link-image" data-testid="element_000209">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>

                <ui-link v-if="key === 'blog'" :link="{ to: `/blog/${link.slug}/` }" :class="`-${key}`" class="ui-search__link" @click="close">
                  <img v-if="link.image?.path" :src="link.image.path" class="ui-search__link-image" data-testid="element_000209">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>

                <ui-link v-else-if="key === 'category'" :link="{ to: `/tag/${link.slug}/` }" class="ui-search__link -category" @click="close">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>

                <ui-link v-else-if="key === 'model'" :link="{ to: `/pornstars/${link.slug}/` }" class="ui-search__link -model" @click="close">
                  <img v-if="link.image?.path" :src="link.image.path" class="ui-search__link-image" data-testid="element_000209">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>

                <ui-link v-else-if="key === 'studio'" :link="{ to: `/studio/${link.slug}/` }" class="ui-search__link -studio" @click="close">
                  <img v-if="link.image?.path" :src="link.image.path" class="ui-search__link-image" data-testid="element_000209">
                  <span class="ui-search__link-text" v-html="`${pickedLinkText(link)}`" />
                </ui-link>
              </template>
            </div>
          </collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'
import { Collapse } from 'vue-collapsed'
import { type ICategory, type ICategoryLink, CATEGORY_LIST  } from '~/shared/types/ui/search'
import apiContentSearchQuick from '~/api/content/search-quick'

const props = defineProps({
  floatingCatalog: {
    type: Boolean,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Search',
  },
})

const pickedLinkText = computed(() => {
  return (link: ICategoryLink) => {
    return link?.highlights?.name?.length ? link.highlights.name[0] : link.name
  }
})

const router = useRouter()

const searchText = ref('')
const loaded = ref(false)

const categories = ref([] as ICategory[])

const inputFocus = ref(false)

const inputClasses = computed(() => {
  return [
    'ui-search__field-input',
    inputFocus.value ? 'ui-search__field-input_focus' : '',
    loaded.value ? 'ui-search__field-input_loaded' : '',
  ]
})

const onReallyDebounce = useDebounceFn(onDebounce, 400)

async function onDebounce() {
  if (searchText.value) {
    loaded.value = true
    try {
      const result = await apiContentSearchQuick({ query: searchText.value, limit: 3 })
      if (result?.$http?.status !== 200) {
        return false
      }

      const categoriesUpdated: ICategory[] = []
      for (const [key, data] of Object.entries(result.data)) {
        const { total, items } = data
        if (!items.length) {
          continue
        }
        categoriesUpdated.push({
          key,
          count: total,
          isExpanded: true,
          links: items,
          ...CATEGORY_LIST[key],
        })
      }

      const keyOrder = ['video', 'model', 'vrcams', 'category', 'studio', 'blog', 'game']
      categoriesUpdated.sort((a, b) => {
        return keyOrder.indexOf(a.key) - keyOrder.indexOf(b.key)
      })

      if (inputFocus.value) {
        categories.value = categoriesUpdated
      } else {
        categories.value = []
      }
    } catch (e) {
      console.log('[SearchQuick] error:', e)
    } finally {
      inputFocus.value = true
      loaded.value = false
    }
  } else {
    close()
  }
}

function openSearchPageByKey(key: string) {
  const keys = {
    video: 'video',
    model: 'pornstars',
    vrcams: 'camgirls',
    category: 'tag',
    studio: 'studios',
    blog: 'blog',
    game: 'games',
  }
  router.push({
    name: 'search-tab',
    params: { tab: keys[key] },
    query: { query: searchText.value.toLowerCase() },
  })
}

function onInput(event: Event) {
  inputFocus.value = true
  searchText.value = (event.target as HTMLTextAreaElement).value
  if (searchText.value && searchText.value.length >= 2) {
    onReallyDebounce()
  }
}

watch(router.currentRoute, () => {
  if (router.currentRoute.value.name === 'search-tab') {
    inputFocus.value = false
  } else {
    close()
  }
})

function changeCategoryExpanded(index: number) {
  const oldVisible = categories.value[index].isExpanded
  categories.value[index].isExpanded = !oldVisible
}

function categoryClasses(styleClass: string) {
  return [
    'ui-search__category',
    styleClass,
  ]
}

function categoryArrowClasses(isExpanded: boolean) {
  return [
    "svg-arrow",
    isExpanded ? 'svg-arrow-down' : 'svg-arrow-right',
  ]
}

function clickOutside() {
  if (!searchText.value) {
    inputFocus.value = false
  }
  if (visibleMobileSearch.value) {
    changeVisibleMobileSearch(false)
  }
  categories.value = []
}

function close() {
  searchText.value = ''
  inputFocus.value = false
  clickOutside()
}

const {
  visibleMobileSearch,
  changeVisibleMobileSearch,
} = useLayoutDefault()

function onSearch() {
  if (searchText.value && searchText.value.length >= 2) {
    const videos = categories.value.find((item) => item.key === 'video')
      openSearchPageByKey('video')

    const first = categories.value[0]
    if (!videos?.count && first?.count) {
      openSearchPageByKey(first.key)
    }

    if (visibleMobileSearch.value) {
      changeVisibleMobileSearch(false)
    }
    close()
  }
}
</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
.ui-search {
  display: inline-block;
  position: relative;

  &__field-input {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding: 13rem 36rem;
      border: none;
      background: map_get($grayPalette, "color_28");
      color: map_get($grayPalette, "color_ff");
      border-radius: 10rem;
      font-size: 16rem;
      line-height: 24rem;
    }
  }

  &__field-input_focus {
    color: map_get($grayPalette, "color_ff");

    .ui-search__field-input-icon {
      &_glass {
        color: map_get($grayPalette, "color_ff");
      }
    }
  }

  &__field-input_loaded {
    input,
    .ui-search__field-input-icon_glass {
      color: map_get($grayPalette, "color_ff");
    }
  }

  &__field-input-icon {
    position: absolute;
  }

  &__field-input-icon_loader {
    right: 14rem;
    width: 20rem;
    height: 20rem;
  }

  &__field-input-icon_close {
    color: map_get($colorPalette, "color_ff6");
    right: 14rem;
    cursor: pointer;
  }

  &__field-input-icon_glass {
    color: map_get($grayPalette, "color_9b");
    left: 14rem;
    pointer-events: none;
  }

  &__categories {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    overflow: auto;
    height: calc(100vh - 56rem);

    @include bp-desktopXS('min') {
      top: 56rem;
      transform: none;
      left: -1rem;
    }

    svg {
      color: map_get($grayPalette, "color_ff");
    }

    &::-webkit-scrollbar {
      width: 2px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: map_get($grayPalette, "color_9b");
    }

    @include bp-mobile() {
      padding: 12rem;
    }
  }

  &__list {
    background: map_get($grayPalette, "color_12");
    border-radius: 10rem;
    padding: 12rem 24rem;
    width: 100%;
  }

  &__category {
    margin-bottom: 14rem;

    .ui-icon {
      font-size: 12rem;
      color: map-get($grayPalette, 'color_9b');
      cursor: pointer
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: map_get($grayPalette, "color_36");
    border-radius: 10rem;
    height: 44rem;
    cursor: pointer;

    .expanded {
      padding: 0 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    padding: 14rem 10rem;
  }

  &__title {
    color: map_get($grayPalette, "color_ff");
    font-weight: 600;
    font-size: 16rem;
    margin-left: 8rem;
    margin-right: 4rem;
    line-height: 1;
  }

  &__count {
    color: map_get($grayPalette, "color_9b");
    font-weight: 600;
    font-size: 14rem;
    line-height: 1;
  }

  &__links {
    padding: 0 14rem;

    a {
      color: map_get($grayPalette, "color_ff");
      font-size: 16rem;
      display: flex;
      border-bottom: 1rem solid map_get($grayPalette, "color_28");
      padding-top: 8rem;
      padding-bottom: 8rem;

      &:first-child {
        margin-top: 10rem;
      }

      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 20rem;
      }

      & :deep([highlight]) {
        color: map-get($colorPalette, 'color_09') !important;
      }
    }

    img {
      display: inline-block;
      margin-right: 10rem;
    }
  }

  &__link {
    &-image {
      width: 46rem;
      height: 26rem;
      aspect-ratio: 46 / 26;
      border-radius: 2rem;
      border: 1px solid map-get($grayPalette, 'color_28');
    }

    &.-studio {
      .ui-search__link-image {
        width: 26rem;
        height: 26rem;
        border: unset;
        aspect-ratio: 1;
      }
    }
    &.-model {
      .ui-search__link-image {
        width: 26rem;
        height: 26rem;
        aspect-ratio: 1;
        border-radius: 26rem;
      }
    }
  }
}
</style>
