// категории
export enum CATEGORY {
  video = 'video',
  category = 'category',
  studio = 'studio',
  model = 'model',
  game = 'game',
  blog = 'blog',
  // TODO: set vrcams alias
  vrcams = 'vrcams',
}

export const CATEGORY_LIST: { [key: string]: unknown } = {
  [CATEGORY.video]: {
    title: 'Videos',
    icon: 'video.svg#video',
    styleClass: 'category_style1',
  },
  [CATEGORY.vrcams]: {
    title: 'VR Cams',
    icon: 'webcam.svg#webcam',
    styleClass: 'category_style1',
  },
  [CATEGORY.category]: {
    title: 'Categories',
    icon: 'categories.svg#categories',
    styleClass: 'category_style1',
  },
  [CATEGORY.studio]: {
    title: 'Studios',
    icon: 'video-record.svg#video-record',
    styleClass: 'category_style3',
  },
  [CATEGORY.model]: {
    title: 'Pornstar',
    icon: 'actress.svg#actress',
    styleClass: 'category_style2',
  },
  [CATEGORY.game]: {
    title: 'Games',
    icon: 'gamepad.svg#gamepad',
    styleClass: 'category_style4',
  },
  [CATEGORY.blog]: {
    title: 'Blog',
    icon: 'document.svg#document',
    styleClass: 'category_style1',
  },
}

export interface ICategoryLink {
  id: string
  slug: string
  name: string
  image?: {
    path: string
  } | null
  highlights: {
    name: string[]
  }
}

export interface ICategory {
  key: string
  title: string
  count: number
  icon: string
  isExpanded: boolean
  links: ICategoryLink[]
  styleClass: string
}
