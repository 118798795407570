<template>
  <div class="app-layout-provider" data-testid="elem_vrp_000673">
    <div v-show="ageCookie || isServer" class="app-layout-provider__content">
      <slot
        name="default"
        :style="{ display: overflow }"
        :is-show-bottom-navigation="isShowBottomNavigation"
      />
    </div>

    <div class="app-layout-provider__screens">
      <lazy-ui-soft-page v-if="canShowSoftPage" />

      <client-only>
        <layout-default-splash-screen
          v-if="!ageCookie && !nvToken"
          :key="`${ageCookie}_slot`"
          :age="Boolean(ageCookie)"
          @change="changeAge"
          @overflow="overflow = $event"
        />

        <layout-default-bottom-navigation v-if="isShowBottomNavigation" />
      </client-only>
    </div>

    <div class="app-layout-provider__dialogs">
      <client-only>
        <ui-activate-account />
        <ui-account-popup :class="isShowHeaderBar && 'header-bar'" />
        <ui-search-mobile-search-modal />
        <ui-account-modal-set-new-password />
        <pages-playa-install-model />
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCookie } from "nuxt/app"
import type { RouteLocationNormalized } from "#vue-router"
import { SOFT_PAGE_COOKIE_OPTIONS } from '~/shared/constants/soft-page'

const isServer = import.meta.server

const nuxt = useNuxtApp()
const router = useRouter()
const nvToken = useState<boolean>('nv-token')

const conditionStore = useConditionStore()
const { layoutIsShowHeaderBar:isShowHeaderBar } = storeToRefs(conditionStore)

const cookieSoftPage = useCookie<boolean>('soft-page', SOFT_PAGE_COOKIE_OPTIONS)
const routesForSoftPageWhitelist: RouteLocationNormalized['name'][] = ['premiumlp', 'login', 'reset-password', 'maintenance', 'callback', 'age-verification', 'part', 'account-setting', 'contact', 'confirm-email']
const isRenderPage = ref(false)

nuxt.hook('page:loading:start', () => {
  isRenderPage.value = !routesForSoftPageWhitelist.includes(router.currentRoute.value.name)
})
nuxt.hook('page:loading:end', () => {
  if (import.meta.server) {
    isRenderPage.value = false
    return
  }
  setTimeout(() => {
    isRenderPage.value = false
  }, 100)
})

const canShowSoftPage = computed(() => {
  if (!cookieSoftPage.value) {
    return false
  }
  if (isRenderPage.value) {
    return true
  }
  const forceHide = routesForSoftPageWhitelist.includes(router.currentRoute.value.name)
  return !forceHide
})

const overflow = ref('block')

const ageCookie = useCookie<boolean | null>('age', {
  default: () => false,
  path: '/',
  maxAge: 60 * 60 * 24 * 30,
  watch: true,
  domain: nuxt.$config.public.NUXT_COOKIE_DOMAIN,
})

watch(ageCookie, (isAge) => {
  if (isAge) {
    location.reload()
  }
})

const changeAge = (state: boolean) => {
  ageCookie.value = Boolean(state) || null
}

const showSlot = computed(() => {
  if (import.meta.server) {
    return true
  }
  return Boolean(ageCookie.value)
})

onMounted(() => {
  setTimeout(() => {
    if (window.performance.getEntriesByType("navigation")[0].type === "back_forward") {
      changeAge(Boolean(ageCookie.value))
    }
  }, 100)
})

const { width } = useSSRWindowSize()

const excludeBottomNavigation = ['premiumlp', 'buy-tokens']

const isShowBottomNavigation = computed(() => {
  return width.value < 600 && !excludeBottomNavigation.includes(router.currentRoute.value.name as string) && ageCookie.value
})
</script>

<style lang="scss">
.splash-screen-hidden {
  display: none;
}
.header-bar {
  margin-top: 50rem
}
</style>
