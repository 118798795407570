<template>
  <client-only>
    <section v-if="isShow" class="ui-activate-account" data-testid="elem_vrp_001059">
      <div class="ui-activate-account-background" data-testid="elem_vrp_001060">
        <div class="ui-activate-account-background__red" data-testid="elem_vrp_001061" />
        <div class="ui-activate-account-background__purple" data-testid="elem_vrp_001062" />
        <div class="ui-activate-account-background__circle" data-testid="elem_vrp_001063" />
        <div class="ui-activate-account-background__image-wrapper" data-testid="elem_vrp_001064">
          <img src="/img/maintenance/bg.png" class="ui-activate-account-background__image" data-testid="elem_vrp_001065" />
        </div>
      </div>

      <div class="ui-activate-account__container" data-testid="elem_vrp_001066">
        <div class="ui-activate-account__wrapper" data-testid="elem_vrp_001067">
          <div class="ui-activate-account__square" data-testid="elem_vrp_001068">
            <svg class="ui-activate-account__square-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
              <circle cx="14.0002" cy="14.5002" r="11.6667" stroke="white" stroke-width="3"/>
              <path d="M9.9165 15.0835L12.2498 17.4168L18.0832 11.5835" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <span class="ui-activate-account__title" data-testid="elem_vrp_001069">Activate Your Account</span>
          <p class="ui-activate-account__text" data-testid="elem_vrp_001070">
            An activation email was sent. Please click the link in the email to activate your account. If you do not see the email, please check your spam folders.
          </p>
          <ui-button
            :disabled="isResend"
            :class="{ 'button_second': isResend }"
            class="button button_primary"
            data-testid="elem_vrp_001071"
            @click="resendLink"
          >
            Resend Link
          </ui-button>
        </div>
      </div>
    </section>
  </client-only>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/user"

const userStore = useUserStore()
const isResend = ref(false)

const isShow = computed(() => {
  return userStore.needEmailConfirm
})

watch(isShow, (value: boolean) => {
  if (import.meta.server) {
    return
  }
  if (value) {
    document.body.style.overflow = 'hidden'
    return
  }
  document.body.style.overflow = 'auto'
})

async function resendLink () {
  if (isResend.value) {
    return
  }
  isResend.value = true
  await userStore.confirmEmailResend()
}
</script>

<style lang="scss">
.ui-activate-account {
  position: fixed;
  inset: 0;
  background-color: map-get($grayPalette, 'color_12');
  z-index: 90;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__wrapper {
    margin: 0 12rem;
    padding: 24rem 12rem;
    width: calc(100% - 24rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24rem 0;
    background: map-get($tonePalette, 'tone_1');
    border-radius: 30rem;

    @include bp-tabletXS('min') {
      margin: 0 24rem;
      padding: 48rem;
      width: calc(100% - 48rem);
    }

    @include bp-desktopXS('min') {
      margin: 0 340rem;
      width: calc(100% - 680rem);
    }
  }

  &__square {
    width: 64rem;
    height: 64rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20rem;
    background: map-get($colorPalette, 'color_81c');

    @include bp-tabletXS('min') {
      width: 72rem;
      height: 72rem;
    }
  }

  &__square-icon {
    max-width: 28rem;
    width: 28rem;
    height: 28rem;

    @include bp-tabletXS('min') {
      max-width: 34rem;
      width: 34rem;
      height: 34rem;
    }
  }

  &__title {
    margin: 0;
    color: map-get($grayPalette, 'color_ff');
    font-size: 18rem;
    font-style: normal;
    font-weight: 600;
    line-height: 27rem;

    @include bp-tabletXS('min') {
      font-size: 22rem;
      line-height: 33rem;
    }
  }

  &__text {
    margin: 0;
    max-width: calc(100% - 24rem);
    color: map-get($grayPalette, 'color_ff');
    text-align: center;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22rem;
    letter-spacing: 0.28rem;

    @include bp-tabletXS('min') {
      max-width: calc(100% - 96rem);
    }

    @include bp-desktopXS('min') {
      max-width: calc(100% - 500rem);
    }
  }

  .ui-button {
    padding: 9rem 0;
    width: 120rem;
    text-transform: unset !important;
  }
}

.ui-activate-account-background {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  inset: 0;
  z-index: -1;

  &__red {
    opacity: 0.1;
    filter: blur(86.39814758300781px);
    background-color: #FF6262;
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(259.481rem / 2);
    height: 496px;
    z-index: 2;

    @include bp-tabletXS('min') {
      width: calc(399.267px / 2);
      height: 763.2px;
      filter: blur(132.94166564941406px);
    }

    @include bp-desktopXS('min') {
      width: 452px;
      height: 864px;
      filter: blur(150.5px);
    }
  }
  &__purple {
    filter: blur(114.81482696533203px);
    background-color: rgba(53, 15, 159, 0.90);
    border-radius: 100%;
    position: absolute;
    right: 0;
    bottom: 106rem;
    width: 201.5rem;
    height: 201.5rem;
    z-index: 3;

    @include bp-tabletXS('min') {
      left: 653.5rem;
      right: unset;
      border-radius: 310.05px;
      width: 310.05px;
      height: 310.05px;
      filter: blur(176.6666717529297px);
    }

    @include bp-desktopXS('min') {
      width: 351px;
      height: 351px;
      border-radius: 351px;
      filter: blur(200px);
      left: unset;
      right: 0;
      bottom: 186rem;
    }
  }
  &__circle {
    filter: blur(114.81482696533203px);
    background-color: rgba(97, 0, 255, 0.90);
    position: absolute;
    border-radius: 100%;
    left: 113rem;
    bottom: 50rem;
    width: 134.907rem;
    height: 134.907rem;
    z-index: 4;

    @include bp-tabletXS('min') {
      width: 207.583px;
      height: 207.583px;
      left: 380.5rem;
      bottom: 76rem;
      filter: blur(247.3333282470703px);
    }

    @include bp-desktopXS('min') {
      left: 1255rem;
      width: 235px;
      height: 235px;
      filter: blur(280px);
    }
  }
  &__image-wrapper {
    width: 373.148px;
    height: 539.056px;
    position: absolute;
    left: 116.8rem;
    bottom: 0;
    z-index: 4;

    @include bp-tabletXS('min') {
      width: 574.167px;
      height: 829.45px;
      left: 393.8rem;
    }

    @include bp-desktopXS('min') {
      width: 650px;
      height: 939px;
      left: unset;
      right: 0;
    }
  }
  &__image {
    object-fit: cover;
    object-position: right;
    border-radius: 10rem;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(2.5px);

    @include bp-tabletXS('min') {
      object-position: 80%;
      filter: blur(4px);
    }
  }
}
</style>

